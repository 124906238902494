@use 'variables';
@use 'mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/bootstrap';
@import 'fonts';
@import 'bootstrap-overrides';
@import 'shared-components';
@import 'ngx-toastr/toastr-bs4-alert';

* {
  box-sizing: border-box;
}

:root {
  --brand: var(--global-accent-color);
}
html {
  min-height: 100vh;
}

body {
  overflow-x: hidden;
  font-family: CircularStd, sans-serif;
  font-weight: 500;
  font-style: 16px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.5;
  min-height: 100%;
}

// footer is always at bottom
app-layout {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

app-footer {
  margin-top: auto;
}

app-social-share-button {
  z-index: 10;
}
.link {
  &:hover {
    color: #007bff;
    text-decoration: underline;
  }
}
h1 {
  @include mixins.mobile {
    font-size: 30px;
  }
}

h4 {
  @include mixins.mobile {
    font-size: 16px;
  }
}

.bg-accent {
  background: var(--accent);
}

.container {
  max-width: 1280px;
}

.tablet-full-width {
  @include mixins.tablet {
    margin: 0 -40px;

    .col-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border-radius: 0 !important;
    }
  }

  @include mixins.mobile {
    margin: 0 -20px;
  }
}

.mobile-full-width {
  @include mixins.mobile {
    margin: 0 -20px;

    .col-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border-radius: 0 !important;
    }
  }
}

.not-full-width {
  @include mixins.mobile {
    &.col-12 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}

.hide-mobile {
  @include mixins.mobile {
    display: none !important;
  }
}

.hide-tablet {
  @include mixins.tablet {
    display: none !important;
  }
}

.hide-desktop {
  @include mixins.desktop {
    display: none !important;
  }
}

.show-mobile {
  @include mixins.mobile {
    display: block !important;
  }
}

.text-color {
  color: var(--text-primary-color);
}

.max-text-width {
  max-width: 600px;
}

.line-height-1 {
  line-height: 1.2;
}

.line-height-0 {
  line-height: 0;
}

.line-height-2 {
  line-height: 1.3;
}

.text-decoration-underline {
  text-decoration: underline;
}

header {
  margin-top: 30px;

  @include mixins.mobile {
    margin-top: 0px;
  }
  .teaser-image {
    position: absolute;
    z-index: -1;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0) 100%);
    top: 0;
    width: -webkit-fill-available;
    width: 100%;
    object-fit: cover;
    height: 100%;
    max-height: 720px;
  }
}

.width-fit-content {
  width: fit-content;
}

.spacer {
  flex-grow: 1;
}

.smcx-embed {
  max-width: 100% !important;
  height: 100% !important;
  .smcx-iframe-container {
    height: 100% !important;
    max-width: 100% !important;
  }
}

.divider {
  border-top: 1px solid rgb(65, 67, 80) !important;
}

.max-width-100 {
  max-width: 100%;
}

.main-bg,
.bg-dark {
  background-color: #1e2433 !important;
}

.bg-dark-x2 {
  background-color: #131926 !important;
}

.modal-content {
  background: transparent;
  border: none;
  border-radius: 6px;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.3);
}

a {
  color: unset;
  cursor: pointer;
}

.anchor {
  font-size: 14px;
  &:hover {
    color: var(--global-accent-color);
    text-decoration: none;
  }
}

a.link {
  text-decoration: underline;
}

strong {
  font-weight: 700 !important;
  color: rgba(var(--text-primary-color), 0.9) !important;
}

.filter-btn {
  display: none;

  @include mixins.mobile {
    display: block;
    transition: background 250ms ease;
    background: #363b47;
  }
}

.filter-form {
  @include mixins.mobile {
    transition: height 250ms ease;
    height: 0px;
    overflow: hidden;
  }

  .mobile-full-width {
    @include mixins.mobile {
      padding: 20px;
      background-color: #131926;
    }
  }
}

.text-medium {
  font-weight: 500 !important;
}

.font-size-xxx-large {
  font-size: xxx-large;
}

.font-size-xx-large {
  font-size: xx-large;
}

.font-size-large {
  font-size: large;
}

.font-size-larger {
  font-size: x-large;
}

.text-note {
  color: #999999;
  font-size: 14px;
  font-weight: 200;
}

.font-size-small {
  font-size: 14px;
}

.btn.ghost-button-light {
  border: 2px solid lightgrey !important;
  border-radius: 10px;
  color: lightgrey !important;
  &:hover {
    background-color: rgba(211, 211, 211, 0.18);
  }
  &:active {
    background-color: rgba(211, 211, 211, 0.35);
    box-shadow: 0 0 5px rgba(211, 211, 211, 0.45);
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #363b47; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1e2433; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 3px solid #363b47; /* creates padding around scroll thumb */
  }

  // firefox
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #1e2433 transparent; /* scroll thumb and track */
}

.custom-scrollbar-transparent {
  &::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1e2433; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 3px solid #070e17; /* creates padding around scroll thumb */
  }

  // firefox
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #1e2433 transparent; /* scroll thumb and track */
}

.d-grid {
  display: grid !important;
}

.toast-error {
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: darken($color: darkred, $amount: 10);
  color: white;
}

.icon-16 svg,
.icon-24 svg,
.icon-32 svg {
  position: absolute;
  top: 0;
  left: 0;
}

.icon-16 {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.icon-24 {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.icon-32 {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.p-12 {
  padding: 12%;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.subpage-sub-header {
  max-height: 240px !important;
}
.subpage-logo-wrapper {
  top: -168px;
  img {
    max-width: 840px;
    z-index: -1;
  }
}

@media screen and (max-width: 992px) {
  .subpage-sub-header {
    max-height: none !important;
    margin-bottom: 100px;
    .subpage-logo-wrapper {
      justify-content: center;
    }
  }
  .subpage-content {
    background: #1e2433;
  }
}

@media screen and (min-width: 992px) {
  .subpage-sub-header {
    max-height: 240px !important;
    .subpage-logo-wrapper {
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 975px) {
  .subpage-logo-wrapper {
    top: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .subpage-logo-wrapper {
    img {
      width: 100%;
    }
  }
}
@include mixins.mobile {
  .sticky-container {
    position: sticky;
    position: -webkit-sticky;
    display: flex;
    flex-direction: column;
    top: 50px;
    z-index: 200;
    padding-bottom: 2rem;
    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .bg-medtoday {
    background-color: #131926;
  }
  .filter-background {
    padding: 8px 0 0 0;
    background-color: #131926;
    padding-bottom: 0;
  }
  .filter-background-patients {
    padding: 16px 8px 0 8px;
    background-color: rgb(230, 230, 230);
    padding-bottom: 0;
  }
}

.link-disabled {
  .event-thumbnail {
    opacity: 0.5;
    &:hover,
    &:focus {
      color: unset;
      text-decoration: none;
    }
  }
}

.z-index-0 {
  z-index: 0;
}

.logo-circle {
  position: absolute;
  height: 300px;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  @include mixins.mobile {
    width: 150px;
    height: 150px;
  }
  img {
    max-width: 300px;
    max-height: 300px;

    @include mixins.mobile {
      max-width: 150px;
      max-height: 150px;
    }
  }
}

section#twitter {
  .twitter-channel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 36px;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 76px;
      height: 76px;
      border: 1px solid rgba(white, 0.1);
      margin-right: 20px;

      &.dark {
        border: 1px solid rgba(black, 0.1) !important;
      }
    }

    span {
      margin-right: 10px;
    }

    .twitter-follow-button {
      display: flex;
      background: #1c95e0;
      border: none;
      padding: 10px 14px;
      color: white;
      border-radius: 5px;
      text-decoration: none;

      &:hover {
        background: ligthen(#1c95e0, 10%);
      }

      div {
        margin-right: 4px;
      }
    }
  }

  .twitter-logo {
    display: block !important;
    position: absolute;
    right: 0;
    max-height: 400px;
    bottom: -90px;
    z-index: -5;
  }
}

@media screen and (min-width: 768px) {
  section#twitter {
    .twitter-logo {
      max-height: 600px;
      bottom: -340px !important;
    }
  }
}

// DEBUG

// img:not([draggable="false"]), a:not([draggable="false"]) {
//   outline: 4px dashed deeppink;
// }

// * { background-color: rgba(255,0,0,.2); }
// * * { background-color: rgba(0,255,0,.2); }
// * * * { background-color: rgba(0,0,255,.2); }
// * * * * { background-color: rgba(255,0,255,.2); }
// * * * * * { background-color: rgba(0,255,255,.2); }
// * * * * * * { background-color: rgba(255,255,0,.2); }
// * * * * * * * { background-color: rgba(255,0,0,.2); }
// * * * * * * * * { background-color: rgba(0,255,0,.2); }
// * * * * * * * * * { background-color: rgba(0,0,255,.2); }
