:root {
  --border-radius: 0.4rem;
  --background-color: #1e2433;
  --background-secondary-color: rgba(255, 255, 255, 0.1);
  --text-primary-color: #ffffff;
  --link-primary-color: rgba(255, 255, 255, 0.7);
  --global-accent-color: #dc2061;
  --separator-color: #3d485e;
  --patients-light-blue: #d5dbff;
  --patients-main-blue: #304eff;
  --background-hover-color: #292f3d;
  --border-color: #ffffffa6;
}
