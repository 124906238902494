@use 'mixins';

.backdrop {
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s;
  -o-animation: fadein 0.5s;
  -ms-animation: fadein 0.5s;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
}

app-cme-congress-page {
  header {
    position: relative;
    margin-top: 0;
    padding-top: 64px;
    padding-bottom: 64px;
    h1 {
      line-height: 48px;
      margin-bottom: 40px;
    }
    img {
      position: absolute;
      width: 50vw;
      left: 50%;
      height: 100%;
      bottom: 0;
      -webkit-mask-image: linear-gradient(to right, transparent 0%, black 100%);
      mask-image: linear-gradient(to right, transparent 0%, black 100%);
      object-fit: cover;
    }
  }
}

.congress-topic-list {
  width: 368px;
  margin-right: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 420px;

  @include mixins.tablet {
    width: 100%;
    margin-right: 0;
  }

  .d-flex {
    gap: 16px;

    @include mixins.tablet {
      gap: 0;
    }
  }

  .congress-topic-inner {
    transition: transform 350ms ease;
    width: 100%;
    flex-shrink: 0;
    transform: translateX(0%);

    &:nth-of-type(2) {
      @include mixins.tablet {
        transform: translateX(50%);
      }
    }

    &.active {
      transform: translateX(calc(-100% - 16px));

      @include mixins.tablet {
        transform: translateX(-100%);
      }
    }

    a {
      font-weight: 400;
      font-size: 15px;
      @include mixins.tablet {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }

    a:hover {
      color: var(--global-accent-color) !important;
      text-decoration: none;

      + .divider {
        border-top: 1px solid var(--global-accent-color) !important;
      }
    }

    .btn {
      background: #474c58;
      line-height: 1;
      font-size: 14px;
      color: white;
      padding: 11px 13px;

      @include mixins.tablet {
        background: var(--global-accent-color);
        text-align: left;
        padding: 16px 13px;
      }
    }
  }
}
