$mobile-width: 576px;
$tablet-width: 991px;
$desktop-width: 992px;

@mixin mobile {
  @media (max-width: $mobile-width) {
      @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet-width) {
      @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-width) {
      @content;
  }
}

@mixin media-query($width) {
  @media (max-width: $width) {
      @content;
  }
}

@mixin max-lines($lines) {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}