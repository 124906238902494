@use 'variables';

/*
  global
*/
/*
  components
*/

// Type
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

h1 {
  font-size: 44px;
}

small {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.container {
  padding-left: 40px;
  padding-right: 40px;
}

// Navbar
.navbar {
  height: 92px;
}

li.nav-item {
  &.active {
    border-bottom: 2px solid lightgrey;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    height: auto;
  }
}

// services background images
.background-image {
  position: absolute;
  top: 0px;
  right: 0px;
  height: auto;
  max-width: 100%;
}

.mobile {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

// carousel {
.carousel-indicators {
  margin: 0 auto;
  position: relative;
  > li {
    height: 8px;
    width: 8px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 100%;
  }
}

// modals
.modal-header {
  border-bottom: none;
}

// Badges
.badge-secondary {
  background-color: var(--secondary);
}

.badge-sponsored {
  background-color: var(--secondary);
  border-radius: 0;
  border-top-left-radius: 10px;
  font-size: 14px;
}

.badge-dark {
  background-color: rgba(255, 255, 255, 0.05);
  font-size: 14px;
  width: fit-content;
}

.badge-light {
  font-size: 14px;
  width: fit-content;
}

.badge {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-dark {
  color: #1e2433 !important;
}

.text-patients {
  color: var(--patients-blue-strong);
  u {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Borders

.border-primary {
  border-color: var(--primary) !important;
}

.border-secondary {
  border-color: var(--secondary) !important;
}

// Buttons
button.btn.dropdown-toggle:focus {
  outline: none !important;
}

.btn {
  padding: 9px 20px 9px 20px;
}

.btn-cme {
  background-color: rgba(#57f2f2, 0.8);
  color: white;
  &:hover {
    color: white;
    background-color: rgba(#57f2f2, 0.6);
  }
}

.btn-featured {
  position: relative;
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: white;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--secondary);
    z-index: -1;
    border-radius: 0.4rem;
    filter: brightness(70%);
    opacity: 0;
    transition: all 0.3s ease-out;
  }
  &:active {
    color: darken(white, 0.3);
    &::after {
      opacity: 1;
    }
  }
  &:hover {
    color: darken(white, 0.3);
    &::after {
      opacity: 1;
    }
  }
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  transition: opacity 250ms;
  opacity: 1;
  &:hover,
  &:focus {
    background-color: var(--primary);
    border-color: var(--primary);
    opacity: 0.8;
    box-shadow: 0 0 0 0.2rem rgb(var(--primary) / 50%);
  }
  &:active {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    box-shadow: 0 0 0 0.2rem rgb(var(--primary) / 50%);
  }
}

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
  transition: opacity 250ms;
  opacity: 1;
  &:hover,
  &:focus {
    background-color: var(--secondary);
    border-color: var(--secondary);
    opacity: 0.8;
    outline: none;
  }
  &:active {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
    opacity: 0.8;
    outline: none;
  }
}

.btn-accent {
  background-color: var(--accent);
  border-color: var(--accent);
  transition: opacity 250ms;
  opacity: 1;
  &:hover,
  &:focus {
    background-color: var(--accent);
    border-color: var(--accent);
    opacity: 0.8;
    outline: none;
  }
  &:active {
    background-color: var(--accent) !important;
    border-color: var(--accent) !important;
    opacity: 0.8;
    outline: none;
  }
}

.btn-danger {
  background-color: var(--global-accent-color) !important;
  border-color: var(--global-accent-color) !important;
  color: white;
  &:hover {
    background-color: darken(#dc2061, 10%);
    border-color: darken(#dc2061, 10%);
  }
  &:active {
    background-color: lighten(#dc2061, 5%) !important;
    border-color: lighten(#dc2061, 5%) !important;
    box-shadow: 0 0 8px lighten(#dc2061, 5%) !important;
  }
  &:focus {
    background-color: darken(#dc2061, 5%);
    border-color: darken(#dc2061, 5%);
  }
}

.btn.btn-dark {
  background-color: darken(slategrey, 20%);
  border-color: darken(slategrey, 20%);
  &:hover {
    background-color: darken(slategrey, 10%);
    border-color: darken(slategrey, 10%);
  }
  &:active {
    background-color: darken(slategrey, 5%) !important;
    border-color: darken(slategrey, 5%) !important;
    box-shadow: 0 0 8px darken(slategrey, 5%) !important;
  }
  &:focus {
    background-color: darken(slategrey, 5%);
    border-color: darken(slategrey, 5%);
  }
}

.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
  &:hover {
    background-color: var(--primary);
    border-color: var(--primary);
  }
}

.btn-outline-danger {
  border-color: var(--global-accent-color);
  color: var(--white);

  &:hover {
    &:not([disabled]) {
      background-color: var(--global-accent-color);
    }
  }
}

.btn.disabled,
.btn:disabled {
  opacity: 0.3;
}

a.text-primary:hover,
a.text-primary:focus {
  color: var(--primary) !important;
  outline: 0px solid transparent !important;
}

button.btn-programme-day {
  // background-color: rgba(white, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background-color: rgb(67 77 87 / 60%);
  color: white;
  font-weight: 500;
  font-size: 14px;
  height: 41px;
  padding-left: 12px;
  padding-right: 12px;
  transition: background-color 0.3s ease;
  &:hover {
    color: white;
    background-color: rgb(86 99 112 / 60%);
  }
  &:focus {
    box-shadow: none !important;
  }
  &.selected {
    background-color: var(--primary);
    color: white;
  }
}

.btn-small {
  padding: 9px 12px 11px 12px;
}
.invalid-feedback,
.text-danger,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: var(--brand) !important;
}

.navigation-menu {
  background-color: rgba(67, 77, 87, 0.6);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  color: white !important;
}

//form input
.form-control:disabled {
  pointer-events: none;
  color: darkgray !important;
}

input.form-control.form-control-lg {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none;
  color: white;
  min-height: 56px;
  box-shadow: none !important;
  &:hover {
    border: 2px solid rgba(white, 0.35);
  }
  &:focus,
  &:active {
    border: 2px solid rgba(white, 0.65) !important;
  }
}

input.form-control.form-control-lg.is-invalid {
  background-image: none;
  border: 2px solid #dc2061 !important;
}

.select-arrow {
  position: absolute;
  right: 30px;
  top: 12px;
  pointer-events: none;
}

.dropdown {
  min-width: 220px;
  z-index: 20;
  &.secondary {
    max-height: 49px;
    .navigation-menu {
      border: 2px solid transparent !important;
      padding: 12px 20px 11px 20px;
      background-color: #363b47 !important;
      h6 {
        color: rgba(white, 0.7);
        font-weight: 400 !important;
      }
      .arrow-indicator {
        transition: all 0.2s ease-out;
        color: white;
      }
    }
    &.show {
      .navigation-menu {
        border: 2px solid var(--border-color) !important;
        border-bottom: 0px solid var(--border-color) !important;
        .arrow-indicator {
          transform: rotate(-180deg);
        }
      }
      .dropdown-toggle {
        background-color: #363b47 !important;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
      }
      .dropdown-menu {
        background-color: #363b47 !important;
        border: 2px solid var(--border-color) !important;
        border-top: 0px solid var(--border-color) !important;
        transform: translate3d(0px, 49px, 0px) !important;
        box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.3);
        z-index: -1;
        .dropdown-item {
          &:hover {
            color: white !important;
          }
        }
      }
      .dropdown-small-gap {
        transform: translate3d(0px, 44px, 0px) !important;
      }
    }
    &.patients {
      .navigation-menu {
        border: 2px solid var(--patients-light-blue) !important;
        color: var(--text-primary-color) !important;
        background-color: white !important;
      }
      &.show {
        .navigation-menu {
          border-bottom: 0px solid var(--patients-light-blue) !important;
        }
        .dropdown-toggle {
          background-color: white !important;
        }
        .dropdown-menu {
          background-color: white !important;
          .dropdown-item {
            &:hover {
              color: var(--text-primary-color) !important;
            }
          }
        }
      }
    }
  }
  &.show {
    z-index: 30;
    .navigation-menu {
      span {
        background-color: transparent;
        &::before {
          transform: translate3d(0, 0, 0) rotate(45deg);
        }
        &::after {
          transform: translate3d(0, 0, 0) rotate(-45deg);
        }
      }
    }
    .dropdown-toggle {
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      background-color: rgba(0, 0, 0, 0.6) !important;
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      transition: none;
    }
    .dropdown-menu {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      transform: translate3d(0px, 52px, 0px) !important;
      background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);

      &.congress-dropdown {
        transform: translate3d(-45%, 52px, 0px) !important;
      }
    }
  }
  .navigation-menu {
    padding: 15px 20px;
    span {
      position: relative;
      display: block;
      width: 19px;
      height: 2px;
      background-color: var(--primary);
      transition: all 0.3s ease-out;
      &::before,
      &::after {
        position: absolute;
        content: '';
        width: 19px;
        height: 2px;
        left: 0;
        background-color: var(--primary);
        transition: all 0.3s ease-out;
      }
      &::before {
        transform: translate3d(0, -6px, 0);
      }
      &::after {
        transform: translate3d(0, 6px, 0);
      }
    }
  }
  .dropdown-toggle {
    &:focus {
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
    }
  }
  .dropdown-menu {
    border: 0px;
    margin: 0;
    z-index: 300;
    .dropdown-item {
      padding: 9px 20px;
      &:hover {
        background: rgba(255, 255, 255, 0.1) !important;
        color: var(--primary) !important;
      }
    }
    a.text-light {
      color: rgba(255, 255, 255, 0.7) !important;
      &.selected {
        color: var(--primary) !important;
      }
    }
  }
}

.dropup {
  &.secondary {
    &.show {
      .navigation-menu {
        border: 2px solid rgba(255, 255, 255, 0.65) !important;
        border-top: 0px solid rgba(255, 255, 255, 0.65) !important;
        border-radius: 0.4rem;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
      .dropdown-menu {
        border: 2px solid rgba(255, 255, 255, 0.65) !important;
        border-bottom: 0px solid rgba(255, 255, 255, 0.65) !important;
        border-radius: 0.4rem;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        transform: translate3d(0px, -500px, 0px) !important;
      }
    }
  }
}

input.form-control.form-control-lg,
input.form-control.form-control-lg {
  min-height: 44px;
  padding: 6px 16px;
  font-size: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.1) !important;

  &:not(.patients-form)::placeholder {
    color: rgba(white, 0.75);
  }
}

label {
  font-size: 0.875em;
  color: rgba(white, 0.7);
  font-weight: 400;
}

.text-transparent {
  color: rgba(255, 255, 255, 0.7) !important;
}

.dropdown-toggle::after {
  display: none !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border: none !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  min-height: 48px;
  padding: 11px 20px 12px 20px;
}

.custom-control-input.cms-toggle {
  ~ .custom-control-label {
    &::before {
      height: 25px;
      width: 40px;
      border-radius: calc(25px / 2);
      border-color: #5d626d !important;
      color: #5d626d !important;
      background-color: #5d626d !important;
    }
    &::after {
      width: 17px;
      height: 17px;
      top: calc(0.25rem + 4px);
      left: calc(-2.25rem + 4px);
      border-color: rgba(white, 0.9) !important;
      color: rgba(white, 0.9) !important;
      background-color: rgba(white, 0.9) !important;
    }
  }
  &:checked {
    ~ .custom-control-label::before {
      border-color: var(--brand) !important;
      background-color: var(--brand) !important;
    }
    ~ .custom-control-label::after {
      transform: translateX(calc(1rem - 1px)) !important;
    }
  }
  &.disabled {
    pointer-events: none;
    ~ .custom-control-label::before {
      pointer-events: none;
      cursor: not-allowed;
      border-color: #5d626d !important;
      color: #5d626d !important;
      background-color: #5d626d !important;
    }
    ~ .custom-control-label::before {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }
    ~ .custom-control-label::after {
      border-color: rgba(white, 0.9) !important;
      color: rgba(white, 0.9) !important;
      background-color: rgba(white, 0.9) !important;
      opacity: 0.5;
    }
  }
}

.custom-checkbox .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}

.custom-checkbox .custom-control-input:not(.cms-toggle) ~ .custom-control-label::before {
  top: 0;
  width: 27px;
  height: 27px;
  border-radius: 0.2rem;
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.custom-checkbox .custom-control-input:checked:not(.cms-toggle) ~ .custom-control-label::before {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
  outline: none;
  width: 27px;
  height: 27px;
}

.custom-checkbox .custom-control-label::after {
  top: 0;
  width: 1.75rem;
  height: 1.75rem;
}

/*
utilities
*/

// spacing
.pl-20 {
  padding-left: 20px;
}

//text
.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.white-space-prewrap {
  white-space: pre-wrap;
}

// backgrounds
.bg-medtoday-sponsored {
  position: relative;
  z-index: 2;
  background: none;
  border-radius: 8px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--secondary);
    filter: brightness(70%);
    border-radius: 8px;
  }
}

.bg-dark {
  background-color: #1e2433 !important;
}

.h-min-content {
  height: min-content;
}

.bg-white {
  background-color: white !important;
}

.card,
.teaser-card {
  color: var(--light);
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1200px !important;
  }

  .modal-l {
    max-width: 760px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.rounded {
  border-radius: 8px !important;
}
@media screen and (max-width: 992px) {
  .dropdown.secondary .dropdown-menu.show {
    box-shadow: none !important;
  }
}